import toStartCase from './start-case.filter'
import toUpperCase from './upper-case.filter'
import truncate from './truncate.filter'
import toClearBrackets from "@/filters/clear-brackets.filter";

export default {
    install(Vue) {
        Vue.filter('toStartCase', toStartCase)
        Vue.filter('toUpperCase', toUpperCase)
        Vue.filter('truncate', truncate)
        Vue.filter('toClearBrackets', toClearBrackets)
    }
}